@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../node_modules/primeicons/primeicons.css";
@import "../../node_modules/primeng/resources/themes/tailwind-light/theme.css";
@import "../../node_modules/primeng/resources/primeng.min.css";

@import "./fonts";
@import "./vendor";
@import "./components";

*,
::before,
::after {
    border-color: transparent;
}
