@layer base {
  html {
      @apply text-brand-black bg-brand-gray-60 selection:bg-brand-primary-blue-20 selection:text-brand-white;

      font-family: Proxima Nova, system-ui, sans-serif;
      overflow: hidden;
      font-smooth: always;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}

.collapse {
  visibility: collapse;
}

.hidden {
  display: none !important;
}
