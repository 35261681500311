$mobile-md-breakpoint: 415px;
$tablet-sm-breakpoint: 480px;
$tablet-md-breakpoint: 820px;
$mobile-main-content-width: 320px;

// navigation
@media (max-width: $mobile-md-breakpoint), (min-width: $tablet-sm-breakpoint) and (max-width: $tablet-md-breakpoint) {
    .nav-mobile {
        #nav-links {
            @apply hidden;
        }

        &-btn {
            background: url("../../assets/icons/nav-mobile-btn.svg") no-repeat;
            transform: translate(24px, -35px);
            @apply block w-8 h-8 relative;

            &-expanded {
                @extend .nav-mobile-btn;
                transform: translate(202px, -35px) rotate(180deg);
            }
        }

        &-expanded {
            @apply max-w-56;

            & > #nav-links {
                @apply block;
            }
        }

        &-collapsed {
            @apply max-w-10;
        }
    }
}

// header
@media (max-width: $mobile-md-breakpoint) {
    #header {
        @apply max-h-11 w-full;

        & > div {
            @apply flex-auto;

            & > span {
                @apply text-xs;
            }
        }
    }
}

// multiselect members
@media (max-width: 1000px) {
    .members-multiselect {
        width: 100% !important;
    }
}

// layout
@media (max-width: $mobile-md-breakpoint), (min-width: $tablet-sm-breakpoint) and (max-width: $tablet-md-breakpoint) {
    .grid-rows-header-and-content {
        grid-template-rows: 44px 1fr !important;

        .header-mobile {
            @apply max-h-11 z-10;
        }
    }

    .grid-cols-nav-and-content {
        grid-template-columns: 35px 1fr !important;

        & > aside:has(.nav-mobile-expanded) {
            @apply relative z-10;
        }
    }
}

@media (min-width: $tablet-sm-breakpoint) and (max-width: $tablet-md-breakpoint) {
    .nav-mobile {
        @apply mt-9;
    }
}

// club details page
@media (max-width: $mobile-md-breakpoint) {
    .mobile-details-header {
        @apply max-w-max p-4 flex-col;

        & > div > button > .icon {
            @apply h-4 w-4;
        }

        & > div > button {
            font-size: 0.65rem;
        }
    }

    .mobile-club-details {
        max-width: $mobile-main-content-width;
        padding: 1.5rem !important;
    }
}

// tabMenu primeNG component
@media (max-width: $mobile-md-breakpoint) {
    .mobile-tabMenu {
        max-width: $mobile-main-content-width;
        p-tabmenu {
            .p-tabmenu-nav {
                flex-wrap: wrap;
            }

            ul.p-tabmenu-nav li {
                width: 100% !important;
                flex: 0 0 auto;
            }
        }
    }
}

// table primeNG component
@media (max-width: $mobile-md-breakpoint) {
    .p-datatable table {
        max-width: $mobile-main-content-width;
        @apply table-auto min-w-max;
    }
}

@media (max-width: $mobile-md-breakpoint) {
    .mobile-content-management {
        max-width: $mobile-main-content-width;
        padding: 0.5rem;

        .text-base {
            font-size: 0.75rem;
        }
    }
}

// multiselect primeNG component
@media (max-width: $mobile-md-breakpoint) {
    .p-multiselect .p-multiselect-panel {
        @apply max-w-max;
    }
}

// paginator primeNG component
@media (max-width: $mobile-md-breakpoint) {
    .p-paginator {
        @apply flex-col;

        &-element.p-paginator-prev,
        &-element.p-paginator-next {
            @apply hidden;
        }
    }
}

// menu primeNG component
@media (max-width: $mobile-md-breakpoint) {
    .p-menu-overlay {
        top: 40px !important;
    }
}
