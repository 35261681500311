@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Proxima Nova"), url("../../assets/fonts/proxima-nova/ProximaNova-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local("Proxima Nova"), url("../../assets/fonts/proxima-nova/ProximaNova-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Proxima Nova"), url("../../assets/fonts/proxima-nova/ProximaNova-Bold.otf");
}
