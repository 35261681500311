.info {
    &-blue-small {
        @apply text-brand-primary-blue-20;
        height: 16px !important;
        width: 16px !important;
    }
}

.search {
    &-blue-small {
        @apply text-brand-primary-blue-20;
        height: 16px !important;
        width: 16px !important;
    }
}
