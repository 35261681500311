.p-component {
    @apply font-default;

    &.p-treeselect {
        @apply border border-brand-gray-10 rounded shadow-none h-10 bg-transparent w-full flex;
        @apply text-brand-black border;
        @apply outline outline-1 outline-transparent;
        @apply ease-in-out duration-200;
        transition-property: border-color, outline-color;

        &.p-inputwrapper {
            &.p-focus {
                .p-icon-wrapper {
                    // @apply rotate-180 transition-all duration-200 ease-in-out;
                }
            }

            .p-treeselect-trigger {
                @apply w-10;

                &[aria-expanded="true"] {
                    @apply rotate-180 transition-all duration-200 ease-in-out;
                }
            }
        }
        .p-treeselect-label {
            @apply px-3 py-2;
            &.p-placeholder {
                @apply text-sm text-black shadow-none;
            }
        }

        &:not(.p-disabled) {
            @apply hover:border-brand-gray-10;

            &.p-focus {
                @apply shadow-none border-brand-primary-blue-60;
                @apply outline outline-1 outline-brand-primary-blue-60;
            }
        }

        .p-overlay {
            width: 446px !important;
            left: -285px !important;
            top: 45px !important;
            &-content {
                .p-treeselect-panel {
                    .p-treeselect-header {
                        @apply bg-white text-black p-2.5;
                        .p-treeselect-close {
                            display: none !important;
                        }

                        .p-treeselect-filter-container {
                            @apply max-h-8;
                            .p-treeselect-filter {
                                @apply border border-brand-gray-10 rounded px-7 py-1.5;

                                &:focus {
                                    @apply shadow-none;
                                }
                            }
                            .p-treeselect-filter-icon {
                                @apply relative bottom-7 left-2.5 text-brand-primary-blue-100;
                            }
                        }
                    }

                    .p-treeselect-items-wrapper {
                        .p-tree {
                            @apply py-0 px-2;
                            &-wrapper {
                                .p-tree-container {
                                    .p-treenode {
                                        @apply border-b border-brand-gray-10 py-2 px-0 shadow-none font-semibold text-sm;

                                        &.p-treenode-leaf {
                                            @apply border-none py-1.5 pl-0 font-normal;
                                        }

                                        .p-treenode-content {
                                            &:not(.p-highlight):hover {
                                                @apply transition-colors ease-in-out duration-200 bg-brand-primary-blue-0 cursor-pointer;
                                            }
                                            &.p-highlight {
                                                @apply bg-white;
                                            }

                                            &.p-treenode-selectable {
                                                &.p-highlight {
                                                    @apply text-black font-normal;
                                                    .p-checkbox {
                                                        .p-checkbox-box {
                                                            &.p-highlight {
                                                                @apply w-4 h-4 bg-brand-primary-blue-20 border-0 border-transparent;
                                                            }
                                                        }
                                                    }
                                                }

                                                .p-checkbox {
                                                    @apply w-4.5 h-4.5 mr-3;

                                                    .p-checkbox-box {
                                                        @apply w-4.5 h-4.5 border-2 border-brand-gray-10 rounded-sm transition-all duration-200 ease-in-out;

                                                        &.p-highlight {
                                                            @apply bg-brand-primary-blue-60 border-brand-primary-blue-60;
                                                        }

                                                        .p-checkbox-icon {
                                                            &.pi {
                                                                &.pi-check {
                                                                    @apply before:content-none h-4 w-4 bg-center inline-block transition-none;
                                                                    background-size: 16px 16px;
                                                                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.83003 14.1087L2 9.24874L3.35241 7.88795L6.83003 11.3871L14.1717 4L15.5241 5.36078L6.83003 14.1087Z' fill='white'/%3E%3C/svg%3E%0A");
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .p-ripple {
                                                    @apply rotate-90 relative;
                                                    right: -385px !important;

                                                    & > chevronrighticon > svg {
                                                        @apply text-brand-neutral-60;
                                                    }
                                                    &.p-element {
                                                        &.p-tree-toggler {
                                                            @apply text-brand-neutral-60 rotate-180;
                                                        }
                                                    }
                                                }

                                                &:hover {
                                                    @apply bg-brand-primary-blue-0 cursor-pointer;
                                                }
                                            }
                                        }

                                        &:focus > .p-treenode-content {
                                            @apply shadow-none;
                                        }
                                    }
                                }
                            }
                        }

                        &::-webkit-scrollbar {
                            @apply w-2;
                        }

                        &::-webkit-scrollbar-thumb {
                            @apply bg-brand-gray-10 rounded bg-clip-border;
                        }

                        &::-webkit-scrollbar-track {
                            @apply bg-brand-white;
                        }
                    }
                }
            }
        }
    }

    &.p-dropdown {
        .p-dropdown {
            @apply border rounded-r rounded-bl-none rounded-tl-none border-brand-gray-10 h-10;

            &:not(.p-dropdown-disabled).p-focus {
                @apply border rounded-r rounded-bl-none rounded-tl-none border-brand-gray-10 shadow-none;
            }

            .p-inputtext {
                @apply text-sm text-black shadow-none;
            }
        }
        &.p-inputwrapper {
            &.p-focus {
                .p-icon-wrapper {
                    @apply rotate-180 transition-all duration-200 ease-in-out;
                }
            }
        }
    }
    &.p-datatable {
        .p-datatable-table {
            @apply border-separate border-spacing-0;

            .p-datatable-thead {
                @apply sticky top-0;
                @apply after:content-empty after:border-b after:border-b-brand-gray-10 after:w-full after:absolute after:bottom-0;

                > tr {
                    > th {
                        @apply py-5 text-xs font-default font-medium text-brand-neutral-20 bg-brand-primary-blue-10 border border-brand-primary-blue-10;
                        @apply whitespace-nowrap uppercase hover:text-brand-primary-blue-20 first:px-8 first:rounded-tl last:rounded-tr focus:shadow-none;
                        @apply transition-colors ease-in-out duration-200;

                        .p-sortable-column-icon {
                            @apply before:font-eos-icons before:content-chevron-down opacity-0 text-xs;

                            &.pi-sort-amount-up-alt {
                                @apply rotate-180;
                            }

                            &.pi-sort-amount-down {
                                @apply rotate-0;
                            }
                        }

                        &.p-sortable-column {
                            &.p-highlight {
                                .p-sortable-column-icon {
                                    @apply opacity-100 text-brand-primary-blue-20;
                                }
                            }
                        }
                    }
                }
            }

            .p-datatable-tbody {
                .row {
                    @apply table-row bg-brand-white;

                    &.row-link {
                        .icon-container {
                            @apply text-right align-middle;

                            .icon {
                                @apply transition-colors ease-in-out duration-200 h-5 w-5 align-middle stroke-brand-primary-blue-20;
                            }
                        }

                        &:hover {
                            @apply transition-colors ease-in-out duration-200 bg-brand-primary-blue-0 cursor-pointer;

                            .icon-container {
                                .icon {
                                    @apply stroke-brand-primary-blue-60;
                                }
                            }
                        }
                    }

                    > td {
                        @apply first:pl-8 pr-8 py-8 font-default align-middle;
                        @apply border-b border-brand-gray-10 first:border-l last:border-r;

                        .table-cell {
                            &-text {
                                @apply text-sm text-brand-black;
                            }

                            &-chip {
                                @apply py-0.5 px-2 rounded text-xs border-2 capitalize text-brand-neutral-20 inline-flex;

                                &-active,
                                &-ok {
                                    @apply bg-brand-green-40 border-brand-green-40;
                                }

                                &-inactive {
                                    @apply bg-brand-gray-10 border-brand-gray-10;
                                }
                                &-cancelled,
                                &-pending-reinstatement,
                                &-freeze,
                                &-pending-cancel,
                                &-problem,
                                &-expired,
                                &-need-address,
                                &-non-member,
                                &-pending-reinstatement,
                                &-return-for-collection,
                                &-need-phone-number {
                                    background-color: #ffd9c5;
                                    border-color: #ffd9c5;
                                }
                            }

                            &-link,
                            &-linkToCopy {
                                @apply text-brand-primary-blue-20 text-sm underline;
                            }

                            &-date {
                                @apply text-sm;
                            }
                        }
                    }
                }

                > tr {
                    &.p-rowgroup-header {
                        > td {
                            @apply border border-brand-gray-10 border-t-0 bg-brand-gray-40 text-brand-neutral-40 uppercase text-xs font-medium py-3 px-8 sticky top-14.5;
                        }
                    }
                }
            }
        }
    }

    &.p-multiselect {
        @apply w-full text-sm text-brand-black border border-brand-gray-10 shadow-none py-2 px-3 rounded h-10;
        @apply outline outline-1 outline-transparent;
        @apply ease-in-out duration-200;
        transition-property: border-color, outline-color;

        &.p-multiselect-open {
            .p-multiselect-trigger {
                .p-multiselect-trigger-icon {
                    @apply -rotate-180;
                }
            }
        }

        .p-multiselect-header {
            @apply bg-brand-white text-brand-white px-3 pt-4;

            .p-multiselect-close {
                @apply hidden;
            }

            .p-multiselect-filter-container {
                .p-multiselect-filter {
                    &.p-inputtext {
                        @apply pr-3 pl-8 py-1.25;
                    }
                }

                .p-multiselect-filter-icon {
                    @apply right-auto left-3 text-brand-primary-blue-100 text-sm leading-4;
                }
            }
        }

        &:not(.p-disabled) {
            @apply hover:border-brand-gray-10;

            &.p-focus {
                @apply shadow-none border-brand-primary-blue-60;
                @apply outline outline-1 outline-brand-primary-blue-60;
            }
        }

        .p-multiselect-label-container {
            .p-multiselect-label {
                @apply p-0;

                &.p-placeholder {
                    @apply text-brand-neutral-20;
                }
            }
        }

        .p-multiselect-trigger {
            @apply w-auto;

            .p-multiselect-trigger-icon {
                @apply transition-transform duration-200 ease-in-out text-xs text-brand-neutral-60;
            }
        }

        .p-multiselect-panel {
            @apply border border-brand-gray-10 rounded border-solid;

            .p-multiselect-items-wrapper {
                @apply border-0;

                &::-webkit-scrollbar {
                    @apply w-2;
                }

                &::-webkit-scrollbar-thumb {
                    @apply bg-brand-gray-10 rounded bg-clip-border border-r-4 border-white;
                }

                &::-webkit-scrollbar-track {
                    @apply bg-brand-white;
                }

                .p-multiselect-items {
                    @apply py-3 cursor-default;

                    .p-multiselect-item {
                        @apply transition-colors ease-in-out duration-200 cursor-pointer text-sm;
                        @apply px-4 py-2 focus:shadow-none text-brand-black hover:bg-brand-primary-blue-10;

                        &.p-highlight {
                            @apply bg-brand-white hover:bg-brand-primary-blue-10;
                        }

                        &:not(.p-highlight) {
                            &:not(.p-disabled) {
                                @apply hover:text-brand-black;
                            }
                        }

                        .p-checkbox {
                            @apply w-4.5 h-4.5 mr-3;

                            .p-checkbox-box {
                                @apply w-4.5 h-4.5 border-2 border-brand-gray-10 rounded-sm transition-all duration-200 ease-in-out;

                                &.p-highlight {
                                    @apply bg-brand-primary-blue-60 border-brand-primary-blue-60;
                                }

                                .p-checkbox-icon {
                                    &.pi {
                                        &.pi-check {
                                            @apply before:content-none h-4 w-4 bg-center inline-block transition-none;
                                            background-size: 16px 16px;
                                            background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.83003 14.1087L2 9.24874L3.35241 7.88795L6.83003 11.3871L14.1717 4L15.5241 5.36078L6.83003 14.1087Z' fill='white'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .p-multiselect-empty-message {
                        @apply text-sm px-4 py-2 text-brand-black cursor-default;
                    }
                }
            }
        }

        &.p-inputwrapper {
            &.p-focus {
                .p-icon-wrapper {
                    @apply rotate-180 transition-all duration-200 ease-in-out;
                }
            }
        }
    }

    &.p-paginator {
        @apply bg-transparent border-none p-0 rounded-none font-default;

        .p-paginator-current {
            @apply p-0 h-auto text-sm text-brand-neutral-40 m-0 inline-block flex-1 cursor-text select-auto;
        }

        .p-paginator-pages {
            @apply flex gap-1 items-center justify-between;

            .p-paginator-page {
                @apply rounded bg-brand-white text-brand-neutral-40 border-brand-gray-20 transition-all duration-200 ease-in-out outline outline-2 outline-offset-negative-2 outline-transparent focus:outline-brand-primary-blue-20;

                &:not(.p-highlight) {
                    @apply hover:bg-brand-white hover:text-brand-neutral-40 hover:border-brand-gray-20;
                }

                &.p-highlight {
                    @apply mr-0 font-medium border-brand-primary-blue-20;
                }
            }
        }

        .p-paginator-element {
            @apply h-6 w-6 m-0 min-w-0 rounded text-xs focus:shadow-none;

            &.p-highlight {
                @apply font-medium;
            }

            &.p-paginator-prev {
                @apply transition-all duration-100 ease-in-out;
                @apply mr-8 border-brand-gray-20 hover:border-brand-gray-20 bg-brand-white hover:bg-brand-white;
                @apply outline outline-2 outline-offset-negative-2 outline-transparent focus:outline-brand-primary-blue-20;

                &.p-disabled {
                    @apply bg-brand-gray-20 border-brand-gray-10;

                    .p-paginator-icon {
                        &.pi {
                            &.pi-angle-left {
                                @apply text-brand-neutral-80;
                            }
                        }
                    }
                }

                .p-paginator-icon {
                    &.pi {
                        &.pi-angle-left {
                            @apply text-brand-neutral-40;
                        }
                    }
                }
            }

            &.p-paginator-next {
                @apply transition-all duration-100 ease-in-out;
                @apply ml-8 border-brand-gray-20 hover:border-brand-gray-20 bg-brand-white hover:bg-brand-white;
                @apply outline outline-2 outline-offset-negative-2 outline-transparent focus:outline-brand-primary-blue-20;

                &.p-disabled {
                    @apply bg-brand-gray-20 border-brand-gray-10;

                    .p-paginator-icon {
                        &.pi {
                            &.pi-angle-right {
                                @apply text-brand-neutral-80;
                            }
                        }
                    }
                }

                .p-paginator-icon {
                    &.pi {
                        &.pi-angle-right {
                            @apply text-brand-neutral-40;
                        }
                    }
                }
            }
        }
    }

    &.p-tabmenu {
        .p-tabmenu-nav-container {
            .p-tabmenu-nav-content {
                .p-tabmenu-nav {
                    @apply bg-transparent border-b gap-8 border-b-brand-gray-10;

                    .p-tabmenuitem {
                        .p-menuitem-link {
                            @apply pt-0 pl-0 pr-0 pb-2 border-b text-brand-neutral-60 bg-transparent border-b-brand-gray-10 focus:shadow-none hover:text-brand-primary-blue-20 -mb-px transition-colors duration-200 ease-in-out;
                        }

                        &.p-highlight {
                            .p-menuitem-link {
                                @apply text-brand-primary-blue-100 border-b-brand-primary-blue-100 hover:text-brand-primary-blue-100;
                            }
                        }

                        &.p-disabled {
                            .p-menuitem-link {
                                @apply text-brand-neutral-60;
                            }

                            &.p-active-item {
                                .p-menuitem-link {
                                    @apply text-brand-primary-blue-100 border-b-brand-primary-blue-100;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.p-tooltip {
        @apply max-w-5xl pr-0;

        .p-tooltip-text {
            @apply rounded p-4 bg-brand-white text-brand-neutral-20 text-sm;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        }

        .p-tooltip-arrow {
            @apply border-t-brand-white;
        }

        &.p-tooltip-top {
            .p-tooltip-text {
                @apply -translate-y-1;
            }

            .p-tooltip-arrow {
                @apply border-t-brand-white z-10 -translate-y-1 -bottom-0.5;
                margin-left: -0.5em;
                border-width: 0.5em 0.5em 0 0.5em;
            }

            &::before,
            ::after {
                @apply border-transparent;
            }
        }
    }
}

p-calendar {
    .p-calendar {
        @apply border rounded border-brand-gray-10 max-w-36 h-10 flex-row-reverse items-center;
        width: 140px;

        &:not(.p-calendar-disabled).p-focus > .p-inputtext {
            @apply border-none shadow-none;
        }

        .p-inputtext {
            @apply text-sm text-black shadow-none;
        }

        .p-button:focus {
            @apply shadow-none;
        }

        &.p-focus {
            @apply border-brand-primary-blue-60 rounded border outline outline-1 outline-brand-primary-blue-60;
        }

        calendaricon {
            @apply text-brand-primary-blue-100;
        }

        input {
            &::placeholder {
                @apply text-black;
            }
        }
    }
}

.p-datepicker {
    left: 828.2px !important;
    @apply p-0;
    table td {
        @apply px-2 py-0;

        & > span {
            width: 32px;
            height: 21px;
        }

        th > span {
            @apply text-xs text-brand-neutral-40;
        }

        & > span {
            @apply text-sm text-black rounded-none;
        }

        & > span.p-highlight {
            @apply bg-brand-primary-blue-60 text-white;
        }

        // &.p-datepicker-today > span {
        //     @apply bg-white;
        // }
    }

    .p-datepicker-header {
        @apply bg-brand-neutral-95;

        .p-datepicker-title {
            .p-datepicker-month {
                @apply mr-1;
            }
        }

        .p-datepicker-prev,
        .p-datepicker-next {
            @apply text-brand-neutral-40;
        }
    }

    .p-datepicker-calendar-container {
        .p-datepicker-calendar {
            thead {
                th {
                    @apply text-xs text-brand-neutral-60 font-normal;
                }
            }
        }
    }
}
.custom-multiselect-member-adresses {
    .p-overlay {
        min-width: 324px !important;
        top: 40px !important;

        &-content {
            .p-multiselect-panel {
                .p-multiselect-header {
                    padding: 1rem !important;

                    .p-multiselect-filter-container {
                        .p-multiselect-filter {
                            @apply border border-slate-100 rounded;

                            &.p-inputtext {
                                @apply shadow-none;
                            }
                        }
                    }
                    .p-checkbox {
                        display: none !important;
                    }
                    & > * {
                        @apply text-sm text-black;
                    }
                }
                .p-multiselect-items-wrapper {
                    max-height: 504px !important;
                }
            }
        }
    }
}

.custom-locations-overlay-multiselect {
    .p-overlay {
        left: -255px !important;
        min-width: 446px !important;

        &-content {
            @apply shadow;
        }
    }
}

.custom-multiselect {
    &-referral-processes,
    &-member-statuses {
        .p-overlay {
            min-width: 393px !important;
            .p-multiselect-panel {
                .p-multiselect-header {
                    @apply mx-2 pb-0;
                    & > * {
                        @apply text-sm text-black;
                    }
                    .p-multiselect-filter-container {
                        display: none !important;
                    }

                    .p-checkbox {
                        @apply hidden;
                    }
                }
                .p-multiselect-items-wrapper {
                    max-height: 100% !important;
                    .p-multiselect-items {
                        @apply border-y border-brand-gray-10 m-3;
                    }
                }
            }
        }
    }
}

.custom-multiselect-referral-processes {
    .p-overlay {
        left: -253px !important;
    }
}

.custom-dropdown-referral-radio {
    &.p-dropdown {
        @apply max-h-10 shadow-none border border-brand-gray-10 rounded-r rounded-l-none w-full text-sm text-black items-center;
        .p-dropdown-label {
            @apply shadow-none;

            &.p-inputtext {
                @apply shadow-none text-sm;
                width: 161px;
            }
        }

        &:not(.p-disabled).p-focus {
            @apply shadow-none border rounded-r rounded-l-none;
            @apply shadow-none border-brand-primary-blue-60;
            @apply outline outline-1 outline-brand-primary-blue-60;
        }
        .p-overlay {
            @apply top-12 z-10 right-2;
            width: 393px !important;

            &-content {
                @apply shadow;
            }
        }

        .p-dropdown-panel {
            @apply py-4;

            .p-dropdown-items {
                @apply border-y border-slate-100 mx-2;
                .p-dropdown-item {
                    @apply h-10 px-4 py-3 text-sm text-black cursor-pointer items-center;

                    &.p-highlight.p-focus {
                        @apply bg-white;
                        & > div > p-radiobutton > .p-radiobutton > .p-radiobutton-box {
                            @apply relative bottom-0.5 border-2 border-none outline-brand-primary-blue-20;

                            &.p-highlight {
                                @apply bg-brand-primary-blue-60;
                            }
                        }
                    }
                    & > div {
                        & > label {
                            @apply ml-3;
                        }
                    }

                    &:hover {
                        @apply bg-white;
                    }
                }

                .p-radiobutton {
                    .p-radiobutton-box {
                        @apply border-brand-gray-10 border h-4 w-6;

                        &.p-highlight {
                            @apply bg-brand-primary-blue-60;
                        }

                        .p-radiobutton-icon {
                            @apply h-2 w-2 bg-brand-primary-blue-60;
                        }
                    }
                }
                &.p-highlight {
                    @apply bg-white;
                }
            }
        }
    }
}
