.link {
  @apply text-brand-primary-blue-20 hover:text-brand-primary-blue-60 text-sm transition-colors ease-in-out duration-200 cursor-pointer;
}

.img-link {
  @apply cursor-pointer;
  position: relative;

  .img-overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
  }
  img {
      opacity: 1;
      transition: opacity 0.2s;
  }
  &:hover img {
      opacity: 0.5;
      transition: opacity 0.2s;
  }

  &:hover .img-overlay {
      opacity: 0.8;
  }
}

.drop-zone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2300A7E1FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
}

.vertical-align-top {
  vertical-align: top;
}

.validation-required {
  color: rgba(242, 24, 50, 1);
}
