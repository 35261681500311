.button {
  @apply px-6 py-2 transition-colors ease-in-out duration-200 font-medium text-xs rounded;

  &-primary {
      @apply bg-brand-primary-blue-20 border border-brand-primary-blue-20 text-brand-white hover:bg-brand-primary-blue-60 hover:border-brand-primary-blue-60;
      @apply disabled:bg-brand-neutral-90 disabled:border-brand-neutral-90 disabled:text-brand-gray-100 disabled:hover:border-brand-neutral-90 disabled:hover:bg-brand-neutral-90 disabled:cursor-not-allowed;
  }

  &-secondary {
      @apply bg-brand-white border border-brand-primary-blue-20 text-brand-primary-blue-20 hover:bg-brand-primary-blue-0;
      @apply disabled:bg-brand-neutral-95 disabled:border-brand-neutral-90 disabled:text-brand-neutral-90 disabled:hover:bg-brand-neutral-95 disabled:cursor-not-allowed;
  }

  &-link {
      @apply text-sm text-brand-primary-blue-20 p-0 rounded-none;
  }

  &-danger {
      @apply bg-brand-red-20 border border-brand-red-20 text-brand-white hover:bg-brand-red-30 hover:border-brand-red-30;
      @apply disabled:bg-brand-neutral-90 disabled:border-brand-neutral-90 disabled:text-brand-gray-100 disabled:hover:border-brand-neutral-90 disabled:hover:bg-brand-neutral-90 disabled:cursor-not-allowed;
  }

  &-edit {
      @apply items-center justify-start text-brand-primary-blue-20 hover:text-brand-primary-blue-60 text-sm leading-6 p-0 transition-colors ease-in-out duration-200;
  }
}
